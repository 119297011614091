















import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"

@Component
export default class Highlight extends Vue {
	@Prop({type: String, required: true}) url!: string
	@Prop({type: String, required: false, default: "_self"}) target!: string
}
