













import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"

import {Person} from "store/participant/types"
import {Gender} from "lib/types/personal"

@Component
export default class Salutation extends Vue {
	@Prop({type: Object, required: true}) person!: Person

	get isMale() {
		return this.person.gender === Gender.Male
	}
	get isFemale() {
		return this.person.gender === Gender.Female
	}

	formatFirstletterUcase(text: string) {
		if (text) {
			return text.charAt(0).toUpperCase() + text.slice(1)
		} else {
			return ""
		}
	}
}
