





























































































































import Vue from "vue"
import Component from "vue-class-component"
import {State, namespace} from "vuex-class"

import {IconFolder, IconEnvelope, IconPerson, IconStar, IconSuitcase, IconPiggybank} from "custom/icons"

import Amount from "custom/Amount.vue"
import Highlight from "custom/Highlight.vue"
import Highlights from "custom/Highlights.vue"
import Page from "custom/Page.vue"
import Salutation from "custom/Salutation.vue"
import Welcome from "custom/Welcome.vue"

import LinkButton from "components/button/LinkButton.vue"
import Badge from "components/supplemental/Badge.vue"
import CardSection from "components/supplemental/CardSection.vue"
import Card from "components/panel/Card.vue"
import Checkbox from "components/form/Checkbox.vue"
import Jumbotron from "components/panel/Jumbotron.vue"
import List from "components/generic/List.vue"
import Message from "components/widget/Message.vue"
import Notice from "components/supplemental/Notice.vue"
import Duration from "components/data/Duration.vue"

import {StoreAction} from "lib/types/vuex"
import {MeubelParticipant} from "store/participant/types"
import {Document} from "store/communication/types"

const communication = namespace("communication")

@Component({
	components: {
		LinkButton,
		Amount,
		Badge,
		Card,
		CardSection,
		Checkbox,
		Highlight,
		Highlights,
		Jumbotron,
		List,
		Message,
		Notice,
		Page,
		Salutation,
		Welcome,
		Duration,

		// icons
		IconEnvelope,
		IconFolder,
		IconPerson,
		IconPiggybank,
		IconStar,
		IconSuitcase
	}
})
export default class Index extends Vue {
	@State lastLogin!: Date | null

	@State participant!: MeubelParticipant

	@communication.Getter documents!: Array<Document>
	@communication.State pensionCommunication!: boolean
	@communication.Getter unreadDocuments!: number
	@communication.Action downloadDocument!: StoreAction<Document>

	personinfo = {
		accrualPercentage: undefined,
		accruedBenefit: undefined,
		email: undefined,
		employmentUid: undefined,
		expectedBenefit: undefined,
		lowerThreshold: undefined,
		monthlyEmployeePremium: undefined,
		personUid: undefined,
		startDate: undefined,
		upperThreshold: undefined,
		PDLOPAccrued: 22262,
		PDLOPExpected: 54012,
		PDCAP: 0,
		PDCAPExpected: 0,
		PDWIA: 0,
		PDPPBeforeAOW: 0,
		PDPPAfterAOW: 0,
		PDPPOrphan: 0
	}

}
