

















import Vue from "vue"
import Component from "vue-class-component"
import {Prop} from "vue-property-decorator"

import timespan from "lib/date/timespan"
import pad from "lib/number/pad"

@Component
export default class Duration extends Vue {
	@Prop({type: Date}) from!: Date
	@Prop({type: Date, required: true}) until!: Date

	get duration() {
		const duration = timespan(this.from || new Date(), this.until)

		return {
			days: pad(duration.days, 2),
			months: pad(duration.months, 2),
			years: pad(duration.years, 2)
		}
	}

}
